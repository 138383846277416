
export default {
  async selectAccionEjec (Vue, idAccionEjec) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select()
      .from(tables.accion_ejec)
      .innerJoin(
        tables.maccion,
        tables.maccion.idmaccion.eq(tables.accion_ejec.idmaccion)
      )
      .innerJoin(
        tables.orden_trabajo_matsist,
        tables.accion_ejec.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.material_sistema,
        tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
      )
      .where(tables.accion_ejec.idaccion_ejec.eq(idAccionEjec))
      .exec())[0]
  },
}
