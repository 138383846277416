<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      :options="toolbarOptions"
      @click-option="clickToolbarOption"
    >
      <volver-parte-checklist-menu-items
        slot="menuItemsLongPress"
        :idparte-trabajo="routeParams.idparte_trabajo"
        :idchecklist-ot="idsParaVolerAtras.idchecklist_ot"
        :idorden-trabajo-matsist="idsParaVolerAtras.idorden_trabajo_matsist"
        :idparte-trabajo-matsist="idsParaVolerAtras.idparte_trabajo_matsist"
        :idsubsis="idsParaVolerAtras.idsubsis"
      />
    </b10-toolbar>
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
      >
        <template slot="extraSubtitle">
          <span
            v-html="formattedItem.observacionesFormateadas"
          />
        </template>
      </b10-view-summary>
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import { PARTE_TRABAJO, ACCION_EJEC } from '@/utils/consts'
import Data from './AccionEjecViewData'
import VolverParteChecklistMenuItems from '../components/VolverParteChecklistMenuItems'

export default {
  components: {
    VolverParteChecklistMenuItems,
  },
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      parteTrabajo: {},
      toolbarOptions: {
        edit: {
          title: 'Modificar',
          visible: true,
          icon: 'edit'
        },
        delete: {
          title: 'Eliminar',
          visible: false,
          icon: 'delete'
        },
      },
      moreInfoRows: [
        {
          name: 'accion_ejec.facturable',
          label: 'Facturable',
          filter: this.$options.filters.humanizeBoolean
        },
        { name: 'accion_ejec.observaciones', label: 'Observaciones' },
        {
          name: 'accion_ejec.fplanificacion',
          label: 'Fecha de planificación',
          filter: this.$options.filters.shortDate
        },
      ],
      showingDialogs: {
        moreInfo: false,
      },
      idsParaVolerAtras: {
        idchecklist_ot: null,
        idorden_trabajo_matsist: null,
        idparte_trabajo_matsist: null,
        idsubsis: null,
      },
    }
  },
  computed: {
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.accion_ejec.idaccion_ejec
        item.title = item.material_sistema.descripcion
        item.subtitle = (item.accion_ejec.facturable) ? ('Facturable') : ("No facturable")
        item.observacionesFormateadas = this.$options.filters.linebreaksBr(item.accion_ejec.observaciones)
        item.moreInfo = this.buildMoreInfo()
        return item
      } else {
        return {}
      }
    },
    accionEditable () {
      return this.parteTrabajo?.parte_trabajo?.estado === PARTE_TRABAJO.estados.pendiente && this.item.dataset.accion_ejec.idest_accion_ejec === ACCION_EJEC.estados.pendiente
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.parteTrabajo = await this.$offline.parteTrabajo.row(this.routeParams.idparte_trabajo)
      if (this.item.dataset) {
        this.title = `Acción ${this.item.dataset.maccion.descripcion}`
        this.toolbarOptions.edit.visible = this.parteTrabajo?.parte_trabajo?.estado === PARTE_TRABAJO.estados.pendiente
        this.toolbarOptions.delete.visible = this.accionEditable
        this.idsParaVolerAtras.idorden_trabajo_matsist = this.item.dataset.accion_ejec.idorden_trabajo_matsist
        this.idsParaVolerAtras.idsubsis = this.item.dataset.material_sistema.idsubsis
        const parteTrabajoMatsist = await this.$offline.parteTrabajoMatsist.selectParteTrabajoMatsistOrdenTrabajoMatsist(
          this.item.dataset.accion_ejec.idorden_trabajo_matsist
        )
        this.idsParaVolerAtras.idparte_trabajo_matsist = parteTrabajoMatsist.parte_trabajo_matsist.idparte_trabajo_matsist
        const checklistOt = await this.$offline.checklistOt.selectChecklistOtDeIdOrdenTrabajo(this.item.dataset.orden_trabajo_matsist.idorden_trabajo)
        this.idsParaVolerAtras.idchecklist_ot = checklistOt?.checklist_ot.idchecklist_ot
      } else {
        this.itemNotFound()
      }
    },
    async loadItem () {
      this.loadingData = true
      try {
        this.$set(
          this.item, 'dataset', await Data.selectAccionEjec(this, this.routeParams.idaccion_ejec)
        )
      } finally {
        this.loadingData = false
      }
    },
    async clickToolbarOption (option) {
      if (option === this.toolbarOptions.edit) {
        this.$appRouter.push({
          name: 'offline__accion-ejec-edit',
          params: {
            idparte_trabajo: this.routeParams.idparte_trabajo,
            idparte_trabajo_matsist: this.routeParams.idparte_trabajo_matsist,
            idaccion_ejec: this.routeParams.idaccion_ejec
          }
        })
      } else if (option === this.toolbarOptions.delete) {
        this.deleteAccionEjec()
      }
    },
    async deleteAccionEjec () {
      const res = await this.$alert.showConfirm(
        `¿Deseas eliminar la acción "${this.item.dataset.maccion.descripcion}"?`
      )
      if (res) {
        await this.$offline.accionEjec.deleteSync(
          this.item.dataset.accion_ejec.idaccion_ejec,
          this.routeParams.idparte_trabajo
        )
        this.$appRouter.go(-1)
      }
    },
  },
}
</script>
